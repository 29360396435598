import { graphql, navigate } from 'gatsby';
import { ENABLE_AUTO_REFILL } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import Button from 'ui-kit/button/button';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import InfoBox from 'ui-kit/info-box/info-box';
import ToastBox from 'ui-kit/toast-box/toast-box';

import LearnMoreContentModal from 'display-components/auto-refill-modal-contents/learn-more';
import TermsAndConditionsContent from 'display-components/auto-refill-modal-contents/terms-and-conditions';

import { CartReviewSectionHeader } from 'components/cart-review-section-header';
import CartQuickLinks from 'components/cart/cart-quick-links/cart-quick-links';
import { CartTotal } from 'components/cart/cart-total/cart-total.component';
import { PrescriptionInformation } from 'components/cart/order-confirmation/prescription-information.component';
import CartLayout from 'components/layouts/cart/cart.layout';

import {
    accountAutoRefillPlanEligibleSelector,
    accountIsCaliforniaUserSelector
} from 'state/account/account.selectors';
import { resetCart, setOrderCompleteAutoRefillToggle } from 'state/cart/cart.reducers';
import { getCartRoutine } from 'state/cart/cart.routines';
import {
    cartOrderBirdiOrderNumberSelector,
    cartOrderHeaderSelector,
    cartOrderPlacedSelector,
    orderCompleteRxAutoRefillStatusSelector
} from 'state/cart/cart.selectors';
import { resetMedicineCabinetCachedSubStatus } from 'state/medicine-cabinet/medicine-cabinet.reducers';
import {
    medicineCabinetLoadRoutine,
    medicineCabinetToggleAutoRefillForRx
} from 'state/medicine-cabinet/medicine-cabinet.routines';
import {
    medicineCabinetActiveTabSelector,
    medicineCabinetAutoRefillPlanEligibleSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import { PrescriptionObjectPayload } from 'types/prescription';

import { DEFAULT_SHIPPING_ID } from 'util/cart';
import { replaceStringWith } from 'util/string';

import usePopState from 'hooks/usePopState';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

import './index.style.scss';

const OrderConfirmation = ({ data }: { data: GatsbyTypes.CartOrderConfirmationDataQuery }) => {
    const { blueSkyBackground } = data;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { mainUserIsCaregiver, mainUserHasMembership, mainUserHasInsurance, lastOrder } = usePrescriptionFlow();

    // selectors
    const orderHeader = useSelector(cartOrderHeaderSelector);
    const birdiOrderNumber = useSelector(cartOrderBirdiOrderNumberSelector);
    const activeDependentTab = useSelector(medicineCabinetActiveTabSelector);
    const displayShowToggleAutoRefillAll = useSelector(medicineCabinetAutoRefillPlanEligibleSelector);
    const isOrderPlaced = useSelector(cartOrderPlacedSelector);
    const isPlanAutoRefill = useSelector(accountAutoRefillPlanEligibleSelector);
    const isCAResident = useSelector(accountIsCaliforniaUserSelector);
    const autoRefillEnabledRxs = useSelector(orderCompleteRxAutoRefillStatusSelector);

    // We implement this hook here, to avoid the user to gets back to the review order page, when the order has been placed
    usePopState(() => {
        dispatch(resetCart());
    });

    // Prevent to show this page if the order is not sent yet
    useEffect(() => {
        if (!isOrderPlaced) {
            navigate('/secure/medicine-cabinet');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // reload the cart after left the order-confirmation page
    useEffect(
        () => () => {
            dispatch(resetMedicineCabinetCachedSubStatus());
            dispatch(getCartRoutine.trigger());
            dispatch(
                medicineCabinetLoadRoutine.trigger({
                    fetchRxSubStatus: true,
                    selectedDependent: activeDependentTab
                })
            );
        },
        [dispatch]
    );

    const handleAutoRefillToggle = useCallback(
        (rxNumber: string, autoRefillEnabled: boolean) => {
            dispatch(setOrderCompleteAutoRefillToggle({ rxNumber, status: autoRefillEnabled }));
            dispatch(
                medicineCabinetToggleAutoRefillForRx.trigger({
                    rxNumber: rxNumber,
                    autoRefillEnabled: autoRefillEnabled,
                    onSuccess: () => {
                        dispatch(closeModalComponent());
                    },
                    onFailure: () => {
                        dispatch(closeModalComponent());
                        dispatch(setOrderCompleteAutoRefillToggle({ rxNumber, status: !autoRefillEnabled }));
                    }
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    const handleAutoRefillInCard = useCallback(
        (prescription: PrescriptionObjectPayload, value: boolean) => {
            if (!prescription.autoRefillEnabled) {
                handleShowTCModal(prescription.rxNumber, value);
            } else {
                handleAutoRefillToggle(prescription.rxNumber, value);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleAutoRefillToggle]
    );

    const handleCloseModal = () => {
        dispatch(closeModalComponent());
    };

    const handleShowTCModal = useCallback(
        (rxNumber: string, autoRefillEnabled: boolean) => {
            dispatch(
                openModalComponent({
                    title: t('pages.profile.autoRefill.autoRefillTC.modalTitle'),
                    isCloseable: true,
                    customDialogClassName: 'learn-more-modal-header',
                    hasModalHeader: true,
                    variation: 'small',
                    isCentered: true,
                    hasCustomContent: false,
                    hasDefaultFooter: true,
                    continueButtonLabel: t('button.confirm'),
                    content: <TermsAndConditionsContent />,
                    onContinue: () => handleAutoRefillToggle(rxNumber, autoRefillEnabled),
                    onClose: handleCloseModal,
                    onCancel: handleCloseModal
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, handleAutoRefillToggle, t]
    );

    const handleAutoRefillModal = useCallback(() => {
        dispatch(
            openModalComponent({
                title: t('pages.profile.autoRefill.learnMore.label'),
                isCloseable: true,
                customDialogClassName: 'learn-more-modal-header',
                hasDefaultFooter: false,
                hasModalHeader: true,
                variation: 'small',
                isCentered: true,
                hasCustomContent: false,
                content: <LearnMoreContentModal />,
                onClose: handleCloseModal
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, t]);

    return (
        <CartLayout
            backgroundImage={blueSkyBackground}
            nodeTitle={t('pages.cartOrderConfirmation.title')}
            title={t('pages.cartOrderConfirmation.headlineText')}
            eyebrowText={t('pages.cartOrderConfirmation.eyebrowText')}
            variant="confirmation"
        >
            <>
                <Container fluid>
                    <Row className="cart-order-confirmation-order-message">
                        <Col xs={12} md={12} lg={12}>
                            <h4
                                dangerouslySetInnerHTML={{
                                    __html: t('pages.cartOrderConfirmation.orderMessage', {
                                        orderNumber: mainUserIsCaregiver
                                            ? birdiOrderNumber
                                            : orderHeader?.orderInvoiceNumber
                                    })
                                }}
                            ></h4>
                        </Col>
                    </Row>
                </Container>
                <CartReviewSectionHeader
                    label={t('pages.cart.prescriptionInformation')}
                    pricingLabel={t('pages.cart.pricing')}
                />

                <PrescriptionInformation
                    onPrescriptionAutoRefillChange={handleAutoRefillInCard}
                    autoRefillEnabledRxs={autoRefillEnabledRxs}
                />
                <CartTotal
                    t={t}
                    disclaimerText={t('pages.cart.totalDisclaimerOrderConfirmation')}
                    total={lastOrder?.total}
                    subTotal={lastOrder?.subTotal}
                    shipMethodPrice={lastOrder?.shipMethodPrice}
                    hasInsurance={mainUserHasInsurance}
                    itemHasUnknownPrice={lastOrder?.itemHasUnknownPrice}
                    hasMembership={mainUserHasMembership}
                />
                <CartReviewSectionHeader label={t('pages.cartOrderConfirmation.sectionHeaders.shippingInformation')} />
                <Container fluid>
                    <Row>
                        <Col className="cart-order-confirmation-section-content">
                            <div className="cart-order-confirmation-default-address-label">
                                <h6>{t('pages.cartOrderConfirmation.shippingInformation.defaultAddressLabel')}</h6>
                            </div>
                            <div className="cart-order-confirmation-default-address">
                                {lastOrder?.address && (
                                    <>
                                        <span className="cart-order-confirmation-section-sentence">
                                            {lastOrder?.address.address1}{' '}
                                            {lastOrder?.address.address2 ? lastOrder?.address.address2 : ''}
                                        </span>
                                        <span className="cart-order-confirmation-section-sentence">
                                            {lastOrder?.address.city}, {lastOrder?.address.state}{' '}
                                            {lastOrder?.address.zipcodeFour
                                                ? `${lastOrder?.address.zipcode}-${lastOrder?.address.zipcodeFour}`
                                                : lastOrder?.address.zipcode}
                                        </span>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
                <CartReviewSectionHeader label={t('pages.cartOrderConfirmation.sectionHeaders.shippingMethod')} />
                <Container fluid>
                    <Row>
                        <Col className="cart-order-confirmation__shipping-method">
                            <p>
                                {lastOrder?.shipMethodId === DEFAULT_SHIPPING_ID
                                    ? t('pages.cart.freeShipping')
                                    : t('pages.cart.expeditedShipping')}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ToastBox icon="default" variant="info">
                                {t('pages.cart.shippingFooter')}
                            </ToastBox>
                        </Col>
                    </Row>
                </Container>
                <CartReviewSectionHeader label={t('pages.cartOrderConfirmation.sectionHeaders.paymentInformation')} />
                <Container fluid>
                    <Row>
                        <Col className="cart-order-confirmation-section-content">
                            <div className="cart-order-confirmation-default-payment-method-label">
                                <h6>{t('pages.cartOrderConfirmation.paymentInformation.primaryPaymentMethod')}</h6>
                            </div>
                            <div className="cart-order-confirmation-default-payment-method">
                                {lastOrder?.paymentMethod && (
                                    <>
                                        <span className="cart-order-confirmation-card-info">
                                            {t('pages.cartOrderConfirmation.paymentInformation.cardEnding', {
                                                cardType: lastOrder.paymentMethod.cardType,
                                                endingDigits: replaceStringWith(
                                                    lastOrder.paymentMethod.secureCardNumber,
                                                    new RegExp(/\*/g),
                                                    ''
                                                )
                                            })}
                                            <br />
                                            {lastOrder.paymentMethod.cardName}
                                            <br />
                                            Exp. {lastOrder.paymentMethod.cardExpiration}
                                        </span>
                                        <CreditCardIcon
                                            className="payment-card__credit-card-icon"
                                            variant={lastOrder.paymentMethod.cardType}
                                        />
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
                {ENABLE_AUTO_REFILL && displayShowToggleAutoRefillAll && isPlanAutoRefill && !isCAResident && (
                    <InfoBox
                        button={
                            <Button
                                onClick={handleAutoRefillModal}
                                className="p-0 btn-demi d-block text-cerulean"
                                variant="text"
                                label={t('pages.medicineCabinet.autoRefillLearnMoreLink')}
                                type="button"
                            />
                        }
                    >
                        <p
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(t('pages.cartOrderConfirmation.turnOnAutoRefill'), {
                                    allowedTags: ['strong']
                                })
                            }}
                        ></p>
                    </InfoBox>
                )}
                <CartReviewSectionHeader label={t('pages.prescriptionConfirmation.sectionHeaders.quickLinks')} />
                <CartQuickLinks />
            </>
        </CartLayout>
    );
};

export default OrderConfirmation;

export const query = graphql`
    query CartOrderConfirmationData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
        allMenuLinkContentFooterLegal(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allBlockContentAddresses(sort: { fields: changed, order: DESC }) {
            nodes {
                field_physical_address
                field_mailing_address
                field_specialty_mailing_address
                field_specialty_physical_address
            }
        }
    }
`;
