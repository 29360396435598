import { ENABLE_BIRDI_SELECT } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import BirdiPriceIcon from 'ui-kit/icons/birdi-price/birdi-price-icon';
import PlanBadge from 'ui-kit/icons/plan-badge/plan-badge';

import AutoRefillBadge from 'components/medicine-cabinet-cart/prescription-information/auto-refill-badge/auto-refill-badge';

import { lastOrderSelector } from 'state/cart/cart.selectors';

import { formatPrice } from 'schema/price.schema';

import { CartOrderRx } from 'types/cart';
import { PrescriptionObjectPayload } from 'types/prescription';

import useCart from 'hooks/useCart';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

import { AutoRefillDisclaimer } from './auto-refill-disclaimer.component';

export const PrescriptionInformation: React.FC<{
    onPrescriptionAutoRefillChange: (prescription: PrescriptionObjectPayload, value: boolean) => void;
    autoRefillEnabledRxs: { rxNumber: string; status: boolean }[];
}> = ({ onPrescriptionAutoRefillChange, autoRefillEnabledRxs }) => {
    const { t } = useTranslation();
    const { mainUserIsCaregiver } = usePrescriptionFlow();
    const { prescriptionsInCart } = useCart();
    const lastOrder = useSelector(lastOrderSelector);

    const formatBirdiSavings = useCallback(
        (item: CartOrderRx): string => {
            if (!item.cartPrice) return '';

            const hasDiscount =
                !!item.cartPrice?.discount && item.cartPrice?.discount > 0 && item.cartPrice.showStrikeThruPricing;

            if (hasDiscount) {
                return t('components.medicineCabinetCart.prescriptionInfo.save', {
                    save: formatPrice(item.cartPrice?.discount)
                });
            } else {
                return '';
            }
        },
        [t]
    );

    const getOrderInvoiceNumber = (rxNumber: string) => {
        const prescription = prescriptionsInCart?.find((rx) => rx.rxDetails.rxNumber === rxNumber);
        return prescription?.rxDetails.prescriptionDetail?.orderInvoiceNumber || '';
    };

    return (
        <>
            <Container className="cart-prescription" fluid>
                {lastOrder?.patients.map((patient) =>
                    patient.cartRxs.map((item) => {
                        const rxDetails = item.rxDetails.prescriptionDetail;
                        const hasDiscount =
                            !!item.cartPrice?.discount &&
                            item.cartPrice?.discount > 0 &&
                            item.cartPrice.showStrikeThruPricing;

                        const isAutoRefillEnabledRx = autoRefillEnabledRxs.find(
                            (rx) => rx.rxNumber === item.rxDetails.rxNumber && rx.status === true
                        );
                        const autoRefillEnabled = isAutoRefillEnabledRx
                            ? isAutoRefillEnabledRx.status
                            : item.autoRefillEnabled;

                        const showAutoRefillFlag = autoRefillEnabled && patient.planEligibleForAutoRefill;

                        const showBirdiPriceIcon = item.cartPrice?.hasKnownPrice && item.cartPrice?.showBirdiCashPrice;

                        const hasBirdiSelect = !!ENABLE_BIRDI_SELECT && item.isBirdiSelect;

                        return (
                            <Row key={item.rxDetails.rxNumber} className="cart-prescription--item">
                                <Col xs={12} className="mb-2">
                                    {mainUserIsCaregiver && (
                                        <Row>
                                            <Col>
                                                <h3 className="cart-prescription--patient-name">
                                                    {`${patient.firstName} ${patient.lastName}`}
                                                </h3>
                                            </Col>
                                            <Col>
                                                <h3 className="cart-prescription--reference-order">
                                                    {`${t('pages.cart.referenceOrder')}: ${getOrderInvoiceNumber(
                                                        item.rxDetails.rxNumber
                                                    )}`}
                                                </h3>
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                                <Col xs={7} sm={6} md={8} className="d-flex align-items-stretch flex-column">
                                    <div className="cart-prescription--product-name">
                                        <h3 className="cart-prescription--header">{rxDetails?.dispensedProductName}</h3>
                                        {hasBirdiSelect && <PlanBadge variant="birdi-select-blue" />}
                                    </div>
                                    <div className="cart-prescription--product-info">
                                        <div className="cart-prescription--product-info--rx-number">
                                            Rx #{item.rxDetails.rxNumber}
                                        </div>
                                        {rxDetails?.fillQuantity && (
                                            <div className="cart-prescription--product-info--content">
                                                {t('pages.cart.quantity', {
                                                    fillQuantity: rxDetails?.fillQuantity
                                                })}
                                            </div>
                                        )}
                                        {(rxDetails?.dispensedProductStrength ||
                                            rxDetails?.dispensedProductUnitOfMeasure) && (
                                            <div className="cart-prescription--product-info--content">
                                                {rxDetails?.dispensedProductStrength}
                                                {rxDetails?.dispensedProductUnitOfMeasure}
                                            </div>
                                        )}
                                        {rxDetails?.dispensedProductDosageForm && (
                                            <div className="cart-prescription--product-info--content dosage">
                                                {rxDetails?.dispensedProductDosageForm}
                                            </div>
                                        )}
                                    </div>
                                    <div className="d-none d-md-flex align-items-baseline">
                                        {showAutoRefillFlag && (
                                            <div className="mt-2">
                                                <AutoRefillBadge
                                                    text={t(
                                                        'components.medicineCabinetCart.prescriptionInfo.autoRefill'
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col xs={5} sm={6} md={4} className="text-right text-lg-right">
                                    {/* Pricing logic */}
                                    {!!item.cartPrice && item.cartPrice?.hasKnownPrice ? (
                                        <>
                                            {hasDiscount && (
                                                <div className="cart-prescription--patient-price-line">
                                                    ${item.cartPrice.awpPrice}
                                                </div>
                                            )}
                                            <div className="cart-prescription--patient-price-total">
                                                {formatPrice(item.cartPrice.finalPrice)}
                                            </div>
                                            {/* Est.Copay - just for insurance patients */}
                                            {item.isPrimaryPlanInsurance && !item.cartPrice.showBirdiCashPrice && (
                                                <div className="cart-prescription--patient-price-info">
                                                    {t('components.medicineCabinetCart.prescriptionInfo.copay')}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className="prescription-item-no-price">
                                            {t('components.medicineCabinetCart.prescriptionInfo.noPrice')}
                                        </div>
                                    )}

                                    {showBirdiPriceIcon && (
                                        <div>
                                            {/* Birdi price */}
                                            <div className="cart-prescription--patient-price-info">
                                                <BirdiPriceIcon className="cart-prescription--patient-price-info-icon" />
                                                <div>
                                                    {t('components.medicineCabinetCart.prescriptionInfo.birdiPrice')}
                                                </div>
                                            </div>

                                            {/* Discount price */}
                                            <div className="cart-prescription--patient-price-saving">
                                                {formatBirdiSavings(item)}
                                            </div>
                                        </div>
                                    )}
                                </Col>
                                <Col className="d-block d-md-none" xs={12}>
                                    <div className="d-flex align-items-baseline">
                                        {showAutoRefillFlag && (
                                            <div className="mt-2">
                                                <AutoRefillBadge
                                                    text={t(
                                                        'components.medicineCabinetCart.prescriptionInfo.autoRefill'
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Col>

                                {!autoRefillEnabled &&
                                    rxDetails?.autoRefillEligible &&
                                    patient.planEligibleForAutoRefill && (
                                        <Col className="mt-3">
                                            <AutoRefillDisclaimer
                                                t={t}
                                                prescription={rxDetails}
                                                autoRefillStatus={autoRefillEnabled || false}
                                                onAutoRefillChange={(
                                                    prescription: PrescriptionObjectPayload,
                                                    value: boolean
                                                ) => onPrescriptionAutoRefillChange(prescription, value)}
                                            ></AutoRefillDisclaimer>
                                        </Col>
                                    )}
                            </Row>
                        );
                    })
                )}
            </Container>
        </>
    );
};
